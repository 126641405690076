import { inject, Injectable } from '@angular/core';
import type ReconnectingWebSocket from 'reconnecting-websocket';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type { PreferenceView } from '@cosmos/types-common';
import {
  WebsocketService,
  type WebsocketResponse,
} from '@cosmos/util-websocket';
import { EspRestClient } from '@esp/common/data-access-rest-client';

@Injectable({
  providedIn: 'root',
})
export class ErosWsService extends EspRestClient {
  override url = '';
  private readonly _websocketService = inject(WebsocketService);
  private _socket: ReconnectingWebSocket | null = null;

  constructor(configService: ConfigService) {
    super(configService.get('erosApiUrl'));
  }

  startWsConnection() {
    const tokenizerUri$ = this.http
      .get(`${this.apiUrl}/negotiate`, {
        responseType: 'text',
      })
      .pipe(
        map((it) => JSON.parse(it)),
        map((it) => ({ url: it.url }))
      );

    return tokenizerUri$.pipe(
      switchMap((uri) => this._websocketService.connect$(uri.url)),
      tap((socket) => (this._socket = socket))
    );
  }

  closeWsConnection() {
    return of(this._socket).pipe(
      tap((socket) => {
        socket && this._websocketService.disconnect(socket);
      })
    );
  }

  onWsMessage$(): Observable<PreferenceView<string>> {
    return this._websocketService
      .messages$<WebsocketResponse<PreferenceView<string>>>(this._socket!)
      .pipe(
        filter(
          (it) =>
            it.from === 'server' &&
            it.type === 'message' &&
            it.dataType === 'json'
        ),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((it: any) => it.data as PreferenceView<string>)
      );
  }
}
