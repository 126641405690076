import {
  APP_BOOTSTRAP_LISTENER,
  inject,
  Injectable,
  Injector,
  makeEnvironmentProviders,
  runInInjectionContext,
  type EnvironmentProviders,
} from '@angular/core';

import { injectConfigProperty } from '@cosmos/config';
import { injectNavigator } from '@cosmos/util-common';

export type BrowserMeta = Record<string, { version: number }>;

@Injectable({
  providedIn: 'root',
})
export class BrowserMetaService {
  private _navigator = injectNavigator();

  /**
   * Examples of user agents:
   *
   * Chrome:
   * 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36'
   * 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36'
   *
   * Chrome on iOS:
   * 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/123.0.2924.75 Mobile/14E5239e Safari/602.1'
   *
   * Edge:
   * 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36 Edg/123.0.0.0'
   *
   * Firefox:
   * "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:124.0) Gecko/20100101 Firefox/124.0"
   * "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:124.0) Gecko/20100101 Firefox/124.0"
   *
   * Safari:
   * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15
   *
   * Opera:
   * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36 OPR/106.0.0.0
   *
   * @returns the browser name and a meta. The meta contains the version of the browser.
   */
  getAgentsBrowserMeta(): BrowserMeta {
    const userAgent = this._navigator.userAgent.toLowerCase();

    const matchedBrowsers: Record<string, RegExpMatchArray | null> = {
      edge: userAgent.match(/edg\/(\d+)\./i),
      firefox: userAgent.match(/firefox\/(\d+)\./i),
      safari: userAgent.match(/version\/(\d+)\./i),
      opera: userAgent.match(/opr\/(\d+)\./i),

      // Since the browsers above use the same user agent string as Chrome, we need to check for Chrome last.
      // The Chrome browser in iOS uses CriOS instead of Chrome.
      chrome: userAgent.match(/(chrome)\/(\d+)\.|(CriOS)\/(\d+)\./i),
    };

    for (const browser in matchedBrowsers) {
      if (matchedBrowsers[browser] && matchedBrowsers[browser]?.length) {
        const versionNumber = matchedBrowsers[browser]
          ?.filter((it) => it !== undefined)
          .pop();

        return {
          [browser]: {
            version: parseInt(versionNumber || '0', 10),
          },
        };
      }
    }

    return {
      'Unknown Browser': {
        version: 0,
      },
    };
  }

  /**
   * If a browser is not listed in the browsersToSupport object, it is considered unsupported.
   * @param browsersToSupport The browsers to support and their minimum versions e.g. { chrome: 123, firefox: 88, edge: 90, safari: 14 }
   * @returns
   */
  browserIsSupported(browsersToSupport: Record<string, number>): boolean {
    const browserNameAndMeta: BrowserMeta = this.getAgentsBrowserMeta();

    const [browserName, browserMeta] = Object.entries(browserNameAndMeta)[0];

    return !(
      !browsersToSupport[browserName] ||
      browsersToSupport[browserName] > browserMeta.version
    );
  }
}

/**
 * Provide in the `action` function argument the action that should happen when the browser is not supported.
 */
export function provideBrowserVersionDetector(
  action: () => void
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        const injector = inject(Injector);
        const browserMetaService = inject(BrowserMetaService);
        const supportedBrowsers = injectConfigProperty('supportedBrowsers');

        return () => {
          if (
            supportedBrowsers &&
            !browserMetaService.browserIsSupported(supportedBrowsers)
          ) {
            runInInjectionContext(injector, action);

            if (ngDevMode) {
              console.error(`You are using an unsupported browser`);
            }
          }
        };
      },
    },
  ]);
}
