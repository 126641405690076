import { NgModule } from '@angular/core';

import { NgxsAfterSuccessModule } from '@cosmos/state';
import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { WebsiteSharedActions } from './actions';

@NgModule({
  imports: [NgxsAfterSuccessModule],
  providers: [
    AlgoliaTasksInterceptor.create({
      urlPattern: /neptune\/websites/,
      ActionToDispatch: WebsiteSharedActions.SearchIndexOperationComplete,
    }),
  ],
})
export class EspWebsitesDataAccessWebsitesModule {}
