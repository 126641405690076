import {
  APP_BOOTSTRAP_LISTENER,
  inject,
  makeEnvironmentProviders,
  NgModuleRef,
  type EnvironmentProviders,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { every, has, isEqual } from 'lodash-es';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

import { CosToastService } from '@cosmos/ui-notification-toast';
import { LanguageScope } from '@cosmos/util-translations';
import { AuthFacade } from '@esp/auth/data-access-auth';
import {
  ErosWsService,
  LivePreferencesActions,
} from '@esp/preferences/data-access-preferences';

export function listenOnErosPreferencesChanges(): EnvironmentProviders {
  const providers = [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        const ngModuleRef = inject(NgModuleRef);
        const store = inject(Store);
        const erosWsService = inject(ErosWsService);
        const authFacade = inject(AuthFacade);
        const toastService = inject(CosToastService);

        return () => {
          const userSource$ = authFacade.profile$.pipe(
            map((user) => user),
            distinctUntilChanged(isEqual)
          );
          const destroy$ = new Subject<void>();
          ngModuleRef.onDestroy(() => destroy$.next());

          userSource$
            .pipe(
              switchMap((user) =>
                user
                  ? erosWsService.startWsConnection().pipe(map(() => true))
                  : erosWsService.closeWsConnection().pipe(map(() => false))
              ),
              filter(Boolean),
              switchMap(() => erosWsService.onWsMessage$()),
              filter((message) => {
                const isValidMessage = validateMessage(message);

                if (!isValidMessage) {
                  return false;
                }

                const isForMyCompany =
                  message.CompanyId == (authFacade.user?.CompanyId ?? 0); // accept messages only for the current company
                const isNotFromMe = message.UserId != authFacade.user?.Id; // accept messages from other users
                return isForMyCompany && isNotFromMe;
              }),
              takeUntil(destroy$)
            )
            .subscribe((message) => {
              store.dispatch(
                new LivePreferencesActions.UpdatePreferences(message)
              );

              displayToast(toastService, message.Type);
            });
        };
      },
    },
  ];
  return makeEnvironmentProviders(providers);
}

/**
 * Validate if the message has all the required properties
 */
function validateMessage(message: unknown) {
  const requiredProperties = [
    'CompanyId',
    'Level',
    'ApplicationId',
    'Type',
    'UserId',
    'Value',
  ];
  return every(requiredProperties, (prop) => has(message, prop));
}

function displayToast(toastService: CosToastService, type: string) {
  toastService.showToast({
    title: 'espCommon.preferences-update-dialog.title',
    body: `espCommon.preferences-update-dialog.body.${type}`,
    type: 'info',
    languageScope: LanguageScope.EspCommon,
  });
}
