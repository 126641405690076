<div class="icon">
  <i class="fa fa-palette"></i>
</div>

<div class="label">
  <select
    class="cos-input cos-input--small w-100"
    [ngModel]="current()"
    #featureFlagsSelect
    (ngModelChange)="selectChanged($event)"
  >
    @for (theme of themeOptions; track theme) {
      <option [ngValue]="theme">
        {{ theme }}
      </option>
    }
  </select>
</div>
