import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';

import { AppCuesService } from '@cosmos/analytics/appcues';
import { Dynamics365Service } from '@cosmos/analytics/dynamics365';
import { cosProvide } from '@cosmos/core';
import { useLocalState } from '@cosmos/state';
import type { CosGlobalHeaderNavigationItem } from '@cosmos/types-layout';
import {
  CosGlobalHeaderLocalState,
  CosGlobalHeaderModule,
} from '@cosmos/ui-global-header';
import {
  Breakpoint,
  injectIsScreenWiderThanOrEqual,
} from '@cosmos/util-screen-size';
import { LogoComponent } from '@esp/common/ui-encore-logo';
import { constructHeaderMenu } from '@esp/misc/feature-menu-items';
import {
  NotificationLocalState,
  NOTIFICATIONS_LOCAL_STATE,
} from '@esp/notifications/feature-notifications';
import { GlobalSearchComponent } from '@esp/search/feature-global-search';
import { WhiteLabelName } from '@esp/white-label/types';
import { WhiteLabelLogoPipe } from '@esp/white-label/ui-white-label-pipes';

import { GlobalHeaderLocalState } from './global-header.local-state';

const sideMenuFactory = () =>
  import('@esp/misc/feature-global-sidebar').then(
    (m) => m.GlobalSidebarComponent
  );

@Component({
  selector: 'esp-global-header',
  templateUrl: './global-header.component.html',
  styleUrl: './global-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    CosGlobalHeaderModule,
    LogoComponent,
    GlobalSearchComponent,
    WhiteLabelLogoPipe,
    NgTemplateOutlet,
  ],
  providers: [
    GlobalHeaderLocalState,
    NotificationLocalState,
    cosProvide({
      provide: NOTIFICATIONS_LOCAL_STATE,
      useExisting: NotificationLocalState,
    }),
  ],
})
export class GlobalHeaderComponent {
  readonly WhiteLabelName = WhiteLabelName;

  private readonly _globalHeader = inject(CosGlobalHeaderLocalState);
  private readonly _notificationState = inject(NOTIFICATIONS_LOCAL_STATE);
  protected readonly state = useLocalState(GlobalHeaderLocalState, this);

  readonly showDesktopSearch = injectIsScreenWiderThanOrEqual(Breakpoint.MD);

  readonly hideGlobalSearch = input(false);
  readonly hideGlobalNav = input(false);

  private readonly _unreadNotifications = toSignal(
    this._notificationState.unreadTotal$
  );

  private readonly _appCuesService = inject(AppCuesService, {
    optional: true,
  });
  private readonly _dynamics365Service = inject(Dynamics365Service, {
    optional: true,
  });

  private readonly _elementRef = inject(ElementRef<HTMLElement>);

  readonly headerNavItems = computed<CosGlobalHeaderNavigationItem[]>(() => {
    if (this.hideGlobalNav()) return [];

    const unreadNotifications = this._unreadNotifications() || 0;
    const items = [
      ...constructHeaderMenu(
        this.state.whiteLabelSettings?.HeaderMenu || []
      ).map((item) => {
        if (item.id === 'notifications') {
          return {
            ...item,
            badgeValue: unreadNotifications,
          };
        }

        return item;
      }),

      /**
       * Keeping those definitions here (and not under feature-menu-items) because those
       * are fixed items that controls global header behavior and are not meant to be configurable.
       */
      {
        id: 'menu',
        title: 'Menu',
        classes: '!hidden md:!flex xl:!hidden',
        icon: 'fa fa-bars',
        hideTitle: true,
        sideMenuFactory,
      },
      {
        id: 'search',
        title: 'Search',
        classes: 'md:!hidden',
        icon: 'fa fa-search',
        hideTitle: true,
        function: () => this._globalHeader.toggleSecondaryOpen(),
      },
    ];

    if (!this.hideGlobalSearch()) return items;

    return items.filter((i) => i.id !== 'search');
  });

  constructor() {
    this._appCuesService?.globalHeaderElement$.next(
      this._elementRef.nativeElement
    );
    this._dynamics365Service?.globalHeaderElement$.next(
      this._elementRef.nativeElement
    );
  }
}
