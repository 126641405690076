import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';

import { ThemesService } from '@cosmos/data-access-themes';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { CosTheme } from '@cosmos/types-common';

export abstract class ThemeSelectConfig {
  options?: CosTheme[];
}

@Component({
  selector: 'cos-feature-flags-theme',
  templateUrl: './feature-flags-theme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule],
})
export class CosFeatureFlagsThemeComponent {
  private readonly _config = inject(ThemeSelectConfig);
  private readonly _themesService = inject(ThemesService);
  readonly themeOptions = this._config.options || Object.values(CosTheme);

  readonly current = toSignal(this._themesService.current$);

  selectChanged(selected: CosTheme): void {
    this._themesService.apply(selected);
  }
}

export function withThemeSelect(config?: ThemeSelectConfig) {
  return makeEnvironmentProviders([
    { provide: ThemeSelectConfig, useValue: config || {} },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsThemeComponent);
      },
    },
  ]);
}
