/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { DOCUMENT, ɵgetDOM } from '@angular/common';
import { inject, Injectable, INJECTOR, type Injector } from '@angular/core';
import {
  EVENT_MANAGER_PLUGINS,
  ɵKeyEventsPlugin,
  type EventManager,
} from '@angular/platform-browser';

import { getZoneUnPatchedApi } from '@cosmos/zone-less';

import {
  ensureMarkForCheckIsCalledIfTheAngularZoneIsReEntered,
  unwrapEventName,
  unwrapOriginalListener,
} from './utils';

function getKeyEventsPlugin(injector: Injector) {
  const plugins = injector.get(EVENT_MANAGER_PLUGINS);
  return plugins.find((plugin) => plugin instanceof ɵKeyEventsPlugin);
}

@Injectable()
export class SilentKeyEventPlugin {
  /** Will be set by Angular. */
  manager!: EventManager;

  private readonly _modifier = 'silent';

  private readonly _document = inject(DOCUMENT);
  private readonly _injector = inject(INJECTOR);

  private _keyEventsPlugin?: ɵKeyEventsPlugin;

  addEventListener(
    element: HTMLElement,
    eventName: string,
    wrappedWithPreventDefaultListener: Function
  ) {
    // The `.silent` plugin should be only considered in the browser runtime. The zone.js
    // doesn't patch `EventTarget` on the Node.js side.
    if (ngServerMode) {
      this._keyEventsPlugin ||= getKeyEventsPlugin(this._injector);
      return this._keyEventsPlugin!.addEventListener(
        element,
        eventName,
        wrappedWithPreventDefaultListener
      );
    }

    const originalListener = unwrapOriginalListener(
      wrappedWithPreventDefaultListener
    );

    const { /* enter */ fullKey, /* keyup */ domEventName } =
      ɵKeyEventsPlugin.parseEventName(
        unwrapEventName(eventName, this._modifier)
      )!;

    const handler = ((event: KeyboardEvent) => {
      if (ɵKeyEventsPlugin.matchEventFullKeyCode(event, fullKey)) {
        originalListener(event);
      }
    }) as EventListener;

    ngDevMode && ensureMarkForCheckIsCalledIfTheAngularZoneIsReEntered(handler);

    getZoneUnPatchedApi(element, 'addEventListener').call(
      element,
      domEventName,
      handler,
      false
    );

    return () =>
      getZoneUnPatchedApi(element, 'removeEventListener').call(
        element,
        domEventName,
        handler,
        false
      );
  }

  addGlobalEventListener(
    element: string,
    eventName: string,
    wrappedWithPreventDefaultListener: Function
  ): Function {
    return this.addEventListener(
      ɵgetDOM().getGlobalEventTarget(this._document, element),
      eventName,
      wrappedWithPreventDefaultListener
    );
  }

  supports(eventName: string): boolean {
    return (
      // eventName.endsWith x 49,216,564 ops/sec ±1.01% (63 runs sampled)
      // regexp.test(eventName) x 32,703,855 ops/sec ±2.84% (40 runs sampled)
      eventName.endsWith(this._modifier) &&
      ɵKeyEventsPlugin.parseEventName(
        unwrapEventName(eventName, this._modifier)
      ) !== null
    );
  }
}
