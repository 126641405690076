import {
  APP_BOOTSTRAP_LISTENER,
  inject,
  makeEnvironmentProviders,
  NgModuleRef,
  type EnvironmentProviders,
} from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';

import { AsiAuthService, Auth } from '@asi/auth/data-access-auth';
import { Dynamics365Service } from '@cosmos/analytics/dynamics365';
import { Dialog } from '@cosmos/components/dialog';
import { AuthFacade } from '@esp/auth/data-access-auth';

import type { InactiveUserDialogResult } from '../../dialogs';

import { UserActivityTrackingService } from './user-activity-tracking.service';

export function enableIdleTracking(): EnvironmentProviders {
  const providers = [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        // Do nothing in Node.js.
        if (ngServerMode) {
          return () => {};
        }

        const ngModuleRef = inject(NgModuleRef);
        const actions$ = inject(Actions);
        const dialog = inject(Dialog);
        const authFacade = inject(AuthFacade);
        const authService = inject(AsiAuthService);
        const dynamics365Service = inject(Dynamics365Service);
        const idleTrackingService = inject(UserActivityTrackingService);
        const router = inject(Router);

        return () => {
          const destroy$ = new Subject<void>();
          ngModuleRef.onDestroy(() => destroy$.next());

          const startTracking =
            idleTrackingService.startTracking.bind(idleTrackingService);
          const isLoggedOut$ = actions$.pipe(ofActionSuccessful(Auth.Logout));

          // Case for new tab of an already logged in user
          if (authFacade.user) {
            startTracking();
          }

          idleTrackingService.idleResponse$
            .pipe(
              switchMap(({ response }: InactiveUserDialogResult) =>
                response === 'continue'
                  ? authService.refreshToken().pipe(tap(startTracking))
                  : authFacade.logout(
                      /* redirectUrl */ router.url,
                      /* is401 */ undefined,
                      response === 'autoLogOff'
                    )
              ),
              takeUntil(destroy$)
            )
            .subscribe();

          actions$
            .pipe(ofActionSuccessful(Auth.LoginSuccess), takeUntil(destroy$))
            .subscribe(startTracking);

          isLoggedOut$.pipe(takeUntil(destroy$)).subscribe(() => {
            idleTrackingService.stopTracking();
            dialog.closeAll();
            dynamics365Service.closeWidget();
          });
        };
      },
    },
  ];

  return makeEnvironmentProviders(providers);
}
