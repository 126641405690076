<div class="icon">
  <i class="fa fa-code"></i>
</div>

<div class="label">
  <select
    class="cos-input cos-input--small w-100"
    [(ngModel)]="featureFlagsToolbarComponent.featureFlag"
    #featureFlagsSelect
    (ngModelChange)="changeFeatureFlagMode()"
  >
    @for (item of featureFlagsToolbarComponent.options | keyvalue; track item) {
      <option [ngValue]="item.key">
        {{ item.value.title }}
      </option>
    }
  </select>
  <button
    cos-button
    mod="flat"
    color="primary"
    size="sm"
    class="ml-2"
    icon="fa-solid fa-pizza-slice"
    [attr.aria-label]="'Edit details'"
    (click)="openFeatureFlagsEditor()"
  ></button>
</div>
