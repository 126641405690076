import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { skip } from 'rxjs';

import { CosAnalyticsService } from '@cosmos/analytics/common';
import { CosmosTranslocoService } from '@cosmos/util-translations';

import type { LanguageChangedEvent } from './tracker.event';

@Injectable({
  providedIn: 'root',
})
export class LanguageTrackerService {
  private readonly _analytics = inject(CosAnalyticsService);
  private readonly _translocoService = inject(CosmosTranslocoService);

  initTrackLanguageChanged(): void {
    this._translocoService.langChanges$
      .pipe(skip(1), takeUntilDestroyed())
      .subscribe((newLang) => {
        const event: LanguageChangedEvent = {
          action: 'Language Changed',
          properties: { language: newLang },
        };

        this._analytics.track(event);
      });
  }
}
