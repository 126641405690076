import { withWhiteLabelSelect } from 'esp/white-label/feature-dev-toolbar';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { requestStartsWith } from '@asi/auth/data-access-auth';
import type { AuthServiceConfig } from '@asi/auth/types-auth';
import type { ContentfulConfig } from '@contentful/types-common';
import { DelightedSurvey } from '@cosmos/analytics/delighted';
import { provideDeskPro } from '@cosmos/analytics/deskpro';
import { provideDynamics365 } from '@cosmos/analytics/dynamics365';
import { provideConfig } from '@cosmos/config';
import type { CustomDomainSetup } from '@cosmos/config/item-models';
import {
  withAuthData,
  withFlagsSelect,
  withFlagsToolbarInformation,
  withFlagsTranslations,
  withThemeSelect,
} from '@cosmos/dev-toolbar';
import {
  ExplicitFeatureFlagsService,
  provideFeatureFlagsService,
} from '@cosmos/feature-flags';
import { provideFeatureFlagsToolbarInitializer } from '@cosmos/feature-flags-toolbar';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';
import type { EnvironmentConfig } from '@esp/common/types';
import { withDocumentStyleSelect } from '@esp/orders/feature-dev-toolbar';

import { configOverrides, importOverrides } from './dev-overrides';
import { featureFlagsToolbarOptions } from './features';

// dev-asicentral.com
const ASICENTRAL_DOMAIN =
  configOverrides.asiCentralDomain ||
  process.env.ASICENTRAL_DOMAIN ||
  `dev-asicentral.com`;

const MYPROMOOFFICE_DOMAIN =
  configOverrides.myPromoOfficeDomain ||
  process.env.MYPROMOOFFICE_DOMAIN ||
  `dev-mypromooffice.com`;

const CATALOG_VIEWER_DOMAIN =
  configOverrides.catalogViewerDomain ||
  process.env.CATALOG_VIEWER_DOMAIN ||
  `view.uat-espplus.com`;

const STOREFRONT_DOMAIN =
  configOverrides.storefrontDomain ||
  process.env.STOREFRONT_DOMAIN ||
  'dev-espwebsites.com';

const STOREFRONT_STORES_DOMAIN =
  configOverrides.storefrontStoresDomain ||
  process.env.STOREFRONT_STORES_DOMAIN ||
  'dev-promostore.com';

const production = false;

const contentful: ContentfulConfig = {
  space: '64iaj3sce04d',
  accessToken: 'etxrW4dvOeWqyedaJmK5l32szyy2psQsGHJrG7bNYiI',
  environment: 'uat',
  sponsoredContent: {
    type: 'sponsoredContent',
    space: 'fika9b2zervd',
    accessToken: 'A4eCRB1F6iHLLEhqebYZbx1zvuwT5LSK626RiW6Rj-0',
    environment: 'uat',
  },
  homePageBannersContent: {
    entryId: '2kJnHUGpOjOP0bObWSFbgO',
  },
};

const auth: AuthServiceConfig = {
  Url: `https://api.${ASICENTRAL_DOMAIN}/login`,
  TokenPath: '/oauth2/token',
  StorageStrategy: 'localStorage',
  AuthorizationHeader:
    'Basic NDM5MDpmZmQ5MzgxZjc2N2U0ZjI2Yjg5MTFhN2Q2NzFlOWZiOQ==',
  canIntercept: requestStartsWith([`https://asiservice.${ASICENTRAL_DOMAIN}`]),
};

const storefrontRecordA = '209.46.30.12';
const storefrontCustomDomainSetup: CustomDomainSetup = {
  recordA: storefrontRecordA,
  cname: `custom.${STOREFRONT_DOMAIN}`,
};
const storefrontStoresCustomDomainSetup: CustomDomainSetup = {
  recordA: storefrontRecordA,
  cname: `custom.${STOREFRONT_STORES_DOMAIN}`,
};

export const environment: EnvironmentConfig = {
  production,
  serviceWorkerEnabled: false,

  WespAuthorizationHeader:
    'Basic MTc3OjRkOWZhZmJjYTBkZDdiYTFiMTdiZWJhZjUxZjM4MDVm',

  imports: [
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      filter(action, state) {
        return false;
        // const type = getActionTypeFromInstance(action);
        // return configOverrides.stateLoggingFilter(action, type, state);
      },
      disabled: false,
    }),
    CosmosUtilTranslationsModule.forRoot({
      production,
      setupPersistLangService: true,
      appName: 'encore',
    }),
    ...importOverrides,
  ],

  providers: [
    provideFeatureFlagsService(ExplicitFeatureFlagsService),
    provideConfig({
      asiStats: undefined,
      appInsights: undefined,
      logrocket: undefined,
      vwo: undefined,
      customerPortalUrl: `https://portal.${MYPROMOOFFICE_DOMAIN}`,
      smartlinkApiUrl: `https://api.${ASICENTRAL_DOMAIN}/v1`,
      marsApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/mars/v1`,
      asiServiceApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}`,
      espServiceApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/esp`,
      // https://asiservice.uat-asicentral.com/ardor/Home
      ardorApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/ardor`,
      // https://asiservice.uat-asicentral.com/zeal/Home
      zealApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/zeal`,
      // https://asiservice.uat-asicentral.com/companyinsights/Home
      companyInsightsApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/rosetta`,
      // https://asiservice.uat-asicentral.com/bloom/Home
      bloomApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/bloom`,
      // https://asiservice.uat-asicentral.com/mercury/Home
      mercuryApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/mercury`,
      // https://asiservice.uat-asicentral.com/janus/Home
      janusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/janus`,
      // https://asiservice.uat-asicentral.com/semaphone/Home
      semaphoreApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/semaphore`,
      // https://asiservice.uat-asicentral.com/sirius/Home
      siriusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/sirius`,
      // https://asiservice.uat-asicentral.com/venus/Home
      venusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/venus`,
      // https://asiservice.uat-asicentral.com/vesta/Home
      vestaApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/vesta/api/encore`,
      // https://asiservice.uat-asicentral.com/vulcan/Home
      vulcanApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/vulcan`,
      // https://asiservice.uat-asicentral.com/triton/Home
      tritonApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/triton`,
      proteusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/proteus`,
      // https://asiservice.uat-asicentral.com/neptune/Home
      neptuneApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/neptune`,
      // https://asiservice.uat-asicentral.com/babou/api/rosetta/exchangerecords
      rosettaApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/rosetta`,
      erosApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/eros`,

      // DEV: Temporary fix for dev environment upload until we get imgx working in dev.
      // hydraApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/hydra`,
      hydraApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/esp`,

      // https://asiservice.uat-asicentral.com/callisto/Home
      callistoApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/callisto`,

      pulseApiUrl: `https://pulse.${ASICENTRAL_DOMAIN}`,
      // https://asiservice.uat-asicentral.com/hyperion/Home
      hyperionApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/hyperion/api`,

      // https://asiservice.uat-asicentral.com/vega/Home
      vegaApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/vega`,

      // https://asiservice.uat-asicentral.com/orion
      orionApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/orion`,

      // https://asiservice.uat-asicentral.com/apophis/swagger/index.html
      apophisApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/apophis`,
      telephusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/babou/api/telephus`,

      catalogViewerUrl: `https://${CATALOG_VIEWER_DOMAIN}`,

      authNotifications: `https://api.${ASICENTRAL_DOMAIN}/login/negotiate`,
      commonMediaUrl: `https://commonmedia.${ASICENTRAL_DOMAIN}`,

      storefrontDomain: STOREFRONT_DOMAIN,
      storefrontStoresDomain: STOREFRONT_STORES_DOMAIN,
      storefrontPreviewUrl:
        configOverrides.storefrontPreviewUrl ||
        `https://preview.${STOREFRONT_DOMAIN}`,
      storefrontCustomDomainSetup,
      storefrontStoresCustomDomainSetup,

      myPromoOfficeDomain: MYPROMOOFFICE_DOMAIN,
      auth,
      contentful,

      // Analytics
      appCues: {
        enabled: false,
      },

      heapIo: {
        enabled: false,
      },

      idleTracking: {
        enabled: true,
        countDownMinutes: 5,
        idleThresholdMinutes: 175,
      },

      helpCenter: {
        submitIdeaRecipients: 'support@asicentral.com',
        submitIdeaSubject: `${encodeURIComponent('ESP+ Feedback')}`,
        knowledgebaseUrl: 'https://kbespplus.asicentral.com/en',
        webinarsUrl: `https://support.asicentral.com/asicentral/web/espplus/main.asp`,
        asiMediaUrl: `https://members.asicentral.com/news`,
        asiUniversityUrl: `https://members.asicentral.com/education`,
        marketResearchUrl: `https://members.asicentral.com/research`,
        asiCentralUrl: `https://www.asicentral.com`,
        eventsUrl: `https://www.asicentral.com/events`,
      },

      deskPro: {
        enabled: false,
        helpDeskUrl: 'https://kbespplus.asicentral.com',
      },

      dynamics365: {
        enabled: false,
        id: 'Microsoft_Omnichannel_LCWidget',
        src: 'https://oc-cdn-ocprod.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js',
        appId: 'ec526c27-b26c-4700-895c-c5c109798aad',
        lcwVersion: 'prod',
        orgId: '8600baac-822e-ef11-8e4b-002248282e13',
        orgUrl:
          'https://m-8600baac-822e-ef11-8e4b-002248282e13.us.omnichannelengagementhub.com',
      },

      delighted: {
        enabled: false,
        surveys: {
          [DelightedSurvey.ProductSearchPage]: {
            appKey: '',
            delightedInstanceName: '',
          },
          [DelightedSurvey.DesignStudio]: {
            appKey: '',
            delightedInstanceName: '',
          },
        },
      },

      espProductSearch: {
        pageSize: 160,
        sponsoredContentPlacementRows: [2, 7],
      },

      supportedBrowsers: {
        chrome: 100,
        firefox: 109,
        edge: 118,
        safari: 16,
      },
    }),

    provideDeskPro(),
    provideDynamics365(),

    provideFeatureFlagsToolbarInitializer(
      featureFlagsToolbarOptions,
      withFlagsSelect(),
      withWhiteLabelSelect(),
      withThemeSelect(),
      withDocumentStyleSelect(),
      withFlagsTranslations(),
      withAuthData(),
      withFlagsToolbarInformation({
        url: `/asi/ngx-esp/commit/${process.env['GIT_SHA']}`,
      })
    ),
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
