/// <reference types="zone.js" />

import { loadScript } from './utils';
import { setupZoneFlags, type ZoneFlagsLocalConfig } from './zone-flags';

const polyfills: Promise<void>[] = [];

if (global_isBrowser) {
  // We have users still running Mac OS X 10 with Safari 12.1.2; it does not support web animations,
  // so they end up having bugs like `animate is not a function`.
  if (
    /* supportsWebAnimations */ typeof Element.prototype.animate !== 'function'
  ) {
    polyfills.push(loadScript('/assets/js/web-animations-js.min.js'));
  }

  // We've noticed that many of our users are still running browsers that do not support
  // the newest ES features (e.g. `Object.fromEntries`). Potentially this list might become
  // bigger if we notice that our users require other polyfills.
  if (typeof Object.fromEntries !== 'function') {
    polyfills.push(loadScript('/assets/js/core-js-from-entries.min.js'));
  }
}

export async function loadPolyfills(
  zoneFlagsLocalConfig?: ZoneFlagsLocalConfig
) {
  if (ngServerMode) {
    return;
  }

  setupZoneFlags(zoneFlagsLocalConfig);

  const version = '0.15.0';

  // eslint-disable-next-line no-restricted-globals
  window.cosCanBootstrapPromise = Promise.all(polyfills).then(() => {
    // zone.js must be loaded after all of the APIs are loaded before that're
    // likely required to be patched.
    if (ngDevMode) {
      // Load non-minified version of the library in development mode.
      return loadScript(`/assets/js/zone.js/zone.js-${version}.js`);
    } else {
      return loadScript(`/assets/js/zone.js/zone.js-${version}.min.js`);
    }
  });
}
