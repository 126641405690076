export const releaseFlags = {
  post_mmp: false,
  stores: true,
  dashboard: false,
  dashboard_project_menu: false,
  feedBack: false,
  tbd: false,
  qwik: false,
  payment_providers: true,
  websitesMMP: false,
  custom_domains: false,
  custom_domains_sites: true,
  custom_domains_stores: false,
  inspire_seo_sites: true,
  inspire_seo_stores: true,
  inspire_search: true,
  inspire_presentation_description: true,
  inspire_orders_messages: true,
  white_label: true,
  custom_products: false,
  project_emails: true,
  order_lists_products: true,
  order_list_create_order: false,
  orders_table_event_type_filter: false,
  aggregate_orders: true,
  aggregated_po_margin: false,
  products_information_validation: false,
  website_payment_methods: false,
  website_account_management: false,
  vs_migration: true,
  vs_text_editor: false,
  web_store_projects: true,
  vs_retouch_tool: false,
  company_enrichments: true,
  web_store_project_invoices: false,
  sending_email_from: false,
  confetti: true,
  super_user: true,
  crm_email_page: true,
  crm_global_notes_page: false,
  crm_global_tasks_page: true,
  crm_prospects: true,
  order_detail_actions_usability_improvement: true,
  settings_third_party_system_name: true,
  settings_create_roles: false,
  settings_orders: true,
  crm_contacts_prospect: true,
  store_design_text_module: true,
  store_product_source_type: true,
  store_fundraising: false,
  presentation_product_additional_options: false,
  product_decoration_lock: false,
  product_item_lock: false,
  order_description: false,
  permissions_create_po: false,
  permissions_create_invoice: true,
  web_order_numbers: false,
  decoration_personalized_fields: false,
  notification_project_deleted: false,
  presentation_pdp_more_info_from_velocity_poc: false,
  store_pdp_more_info_from_velocity_poc: false,
};
