import { provideCosMobileSmartbanner } from '@cosmos/ui-mobile-smartbanner';
import { CosPlatform } from '@cosmos/util-platform';

export const provideAppMobileSmartbanner = () => {
  return provideCosMobileSmartbanner({
    [CosPlatform.Android]: {
      appName: 'ESP+',
      appId: 'com.asicentral.espplus',
      storeName: 'Google Play Store',
      logoPath: '/assets/images/app-esp-logo.png',
      storePath:
        'https://play.google.com/store/apps/details?id=com.asicentral.espplus',
    },
    [CosPlatform.Ios]: {
      appName: 'ESP+',
      appId: '6472814106',
    },
  });
};
