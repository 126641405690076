<div class="icon">
  <i class="fa-solid fa-key"></i>
</div>
<div class="label">
  <button
    cos-button
    mod="flat"
    color="primary"
    size="sm"
    (click)="openDetailsModal()"
  >
    View Auth Data
  </button>
  <button
    icon="fa fa-copy"
    cos-button
    mod="flat"
    color="primary"
    size="sm"
    [cosTooltip]="'Copy access token to clipboard'"
    (click)="copyToken()"
  ></button>
</div>
