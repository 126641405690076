export const releaseFlags = {
  post_mmp: false,
  stores: true,
  feedBack: false,
  tbd: false,
  qwik: false,
  websitesMMP: false,
  custom_domains: false,
  custom_domains_sites: true,
  custom_domains_stores: false,
  inspire_seo_sites: true,
  inspire_seo_stores: true,
  inspire_search: true,
  inspire_presentation_description: true,
  custom_products: false,
  orders_table_event_type_filter: false,
  aggregated_po_margin: false,
  website_payment_methods: false,
  website_account_management: false,
  website_page_permissions: false,
  website_localization: false,
  vs_migration: true,
  vs_text_editor: false,
  vs_retouch_tool: false,
  company_enrichments: true,
  web_store_project_invoices: false,
  confetti: true,
  crm_global_notes_page: false,
  settings_third_party_system_name: true,
  settings_create_roles: false,
  settings_orders: true,
  permissions_create_po: false,
  permissions_create_invoice: true,
  notification_project_deleted: false,
  presentation_pdp_more_info_from_velocity_poc: false,
  presentation_product_imprint_options: false,
  store_pdp_more_info_from_velocity_poc: false,
  stores_product_options: true,
  stores_product_imprint_options: true,
  stores_additional_charges: true,
  order_tasks: true,
  add_to_store_flow: false,
  storefront_elfsight_widget: false,
  alternate_order_view_without_project: false,
  allow_standalone_orders_in_flows: true,
  presentation_design: false,
  corporate_templates: false,
  template_search_toggle_status: false,
  company_settings_product_search_default_sort: false,
  store_presentation_product_source: true,
};
