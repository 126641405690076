import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { cosProvide } from '@cosmos/core';
import { useLocalState } from '@cosmos/state';
import type { CosGlobalTabsNavigationItem } from '@cosmos/types-layout';
import { CosGlobalTabsModule } from '@cosmos/ui-global-tabs';
import { constructMobileMenu } from '@esp/misc/feature-menu-items';
import {
  NotificationLocalState,
  NOTIFICATIONS_LOCAL_STATE,
} from '@esp/notifications/feature-notifications';

import { GlobalTabsLocalState } from './global-tabs.local-state';

const sideMenuFactory = () =>
  import('@esp/misc/feature-global-sidebar').then(
    (m) => m.GlobalSidebarComponent
  );

@Component({
  selector: 'esp-global-tabs',
  templateUrl: './global-tabs.component.html',
  styleUrl: './global-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CosGlobalTabsModule, CosGlobalTabsModule],
  providers: [
    GlobalTabsLocalState,
    NotificationLocalState,
    cosProvide({
      provide: NOTIFICATIONS_LOCAL_STATE,
      useExisting: NotificationLocalState,
    }),
  ],
})
export class GlobalTabsComponent {
  private readonly _notificationState = inject(NOTIFICATIONS_LOCAL_STATE);
  protected readonly state = useLocalState(GlobalTabsLocalState, this);

  readonly hideGlobalNav = input(false);

  private readonly _unreadNotifications = toSignal(
    this._notificationState.unreadTotal$
  );
  readonly mobileNavitems = computed<CosGlobalTabsNavigationItem[]>(() => {
    if (this.hideGlobalNav()) return [];

    const unreadNotifications = this._unreadNotifications() || 0;
    return [
      ...constructMobileMenu(
        this.state.whiteLabelSettings?.MobileMenu || []
      ).map((item) => {
        if (item.id === 'notifications') {
          return {
            ...item,
            // TODO: It should have a badge items!
          };
        }

        return item;
      }),
      /**
       * Keeping those definitions here (and not under feature-menu-items) because those
       * are fixed items that controls global header behavior and are not meant to be configurable.
       */
      {
        id: 'menu',
        title: 'Menu',
        icon: 'fa fa-bars',
        badgeValue: unreadNotifications,
        sideMenuFactory,
      },
    ];
  });
}
