import { DialogOverlayWidth } from '@cosmos/components/dialog';
import { createDialogDef } from '@cosmos/ui-dialog';

import type {
  FeatureFlagsEditorDialogData,
  FeatureFlagsEditorDialogResult,
} from './models';

export const featureFlagsEditorDialogDef = createDialogDef<
  FeatureFlagsEditorDialogData,
  FeatureFlagsEditorDialogResult
>({
  load: () => import(`./feature-flags-editor.dialog`),
  defaultConfig: {
    width: DialogOverlayWidth.LG,
    minHeight: '80vh',
  },
});
