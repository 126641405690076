import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NgxsAfterSuccessModule } from '@cosmos/state';
import { CrmCompanyQuickBooksState } from '@esp/crm/data-access-company-quickbooks';
import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { ProjectSharedActions } from './actions';
import { OrderDocumentsNewStyleInterceptor } from './interceptors';
import {
  ProjectCreateWithNewCustomerState,
  ProjectsRecentState,
  ProjectsSearchState,
  ProjectsState,
  StoreProjectsState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ProjectCreateWithNewCustomerState,
      ProjectsRecentState,
      ProjectsSearchState,
      ProjectsState,
      StoreProjectsState,
      CrmCompanyQuickBooksState,
    ]),
    NgxsAfterSuccessModule,
  ],
  providers: [
    AlgoliaTasksInterceptor.create({
      urlPattern: /vulcan\/projects/,
      ActionToDispatch: ProjectSharedActions.SearchIndexOperationComplete,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrderDocumentsNewStyleInterceptor,
      multi: true,
    },
  ],
})
export class EspProjectsDataAccessStoreModule {}
