/* eslint-disable no-restricted-globals */
import {
  APP_BOOTSTRAP_LISTENER,
  inject,
  Injectable,
  type OnDestroy,
  type Provider,
} from '@angular/core';
import { BehaviorSubject, first, map, mergeMap, ReplaySubject } from 'rxjs';

// TODO(ds): imports between cosmos/config and cosmos/analytics should be refactored
// eslint-disable-next-line @nx/enforce-module-boundaries
import { injectConfigProperty } from '@cosmos/config';
import { RAF$, WINDOW_LOAD$ } from '@cosmos/tick-scheduler';
import {
  unpatchedClearInterval,
  unpatchedSetInterval,
} from '@cosmos/zone-less';

import { loadScript } from '../../common/src/load-script';

export interface DeskProConfig {
  enabled?: boolean;
  helpDeskUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class DeskProService implements OnDestroy {
  /**
   * This subject keeps track of the `esp-feature-global-header` element since we should
   * not try to initialize the deskpro widget before the element is rendered. The `userEvent$`
   * subject may emit before the `esp-feature-global-header` is rendered; this will lead to an
   * exception `Cannot read properties of undefined (reading 'appendChild')`.
   */
  readonly globalHeaderElement$ = new BehaviorSubject<HTMLElement | null>(null);

  private _widgetButton?: HTMLAnchorElement;
  private _widgetCloseButton?: HTMLAnchorElement;

  private readonly _config: DeskProConfig = injectConfigProperty('deskPro');

  /** Used to notify the observer once the global header element is set for the first time. */
  private _globalHeaderElementRendered$ = this.globalHeaderElement$.pipe(
    first(
      (globalHeaderElement): globalHeaderElement is HTMLElement =>
        globalHeaderElement !== null
    )
  );

  private readonly _scriptHasBeenLoaded$ = new ReplaySubject<void>(1);

  private _intervalId: number | null = null;

  private readonly _raf$ = inject(RAF$);
  private readonly _windowLoad$ = inject(WINDOW_LOAD$);

  ngOnDestroy(): void {
    this._disposeTimer();
  }

  openWidget(): void {
    this._widgetButton?.click();
  }

  closeWidget(): void {
    this._widgetCloseButton?.click();
  }

  initialize(): void {
    if (!this._config.enabled) {
      return;
    }

    const globalHeaderElementRendered$ = this._windowLoad$.pipe(
      mergeMap(() => this._globalHeaderElementRendered$)
    );

    globalHeaderElementRendered$
      .pipe(
        mergeMap((globalHeaderElement) =>
          this._raf$.pipe(map(() => globalHeaderElement))
        )
      )
      // No reason to unsubscribe because this is the root browser provider,
      // which will be disposed of when the tab is closed.
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((globalHeaderElement) => {
        this._addWidgetOptionScript(globalHeaderElement);
        loadDeskPro(this._config, this._scriptHasBeenLoaded$);
      });

    this._scriptHasBeenLoaded$
      .pipe(mergeMap(() => this._raf$))
      // No reason to unsubscribe because this is the root browser provider,
      // which will be disposed of when the tab is closed.
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this._checkWidgetDiv();
      });
  }

  private _addWidgetOptionScript(globalHeaderElement: HTMLElement): void {
    const deskProMessengerOptions = `window.DESKPRO_WIDGET_OPTIONS = {
        helpdeskURL: '${this._config.helpDeskUrl}',
        baseUrl: '${this._config.helpDeskUrl}',
          }`;

    const elDeskProOptionScript = document.createElement('script');
    elDeskProOptionScript.type = 'text/javascript';
    elDeskProOptionScript.textContent = deskProMessengerOptions;
    globalHeaderElement.appendChild(elDeskProOptionScript);
  }

  private _setAndHideWidgetButton(deskProDiv: HTMLDivElement): void {
    const iframe = deskProDiv.children[0]?.children[0] as HTMLIFrameElement;
    const iframeDocument = iframe?.contentWindow?.document;

    if (!iframeDocument) return;

    const deskproButton = iframeDocument.getElementsByClassName(
      'preemtive-button'
    )[0] as HTMLAnchorElement;

    if (deskproButton) {
      deskproButton.style.display = 'none';
      this._widgetButton = deskproButton;
    } else {
      this._checkWidgetDiv();
    }
  }

  private _setWidgetCloseButton(deskProDiv: HTMLDivElement): void {
    const iframeWidget = deskProDiv.children[0]
      ?.children[1] as HTMLIFrameElement;
    const iframeDocument = iframeWidget?.contentWindow?.document;

    if (!iframeDocument) return;

    const deskproCloseButton = iframeDocument.getElementsByClassName(
      'dpdesignportal-header-controls'
    )[0] as HTMLAnchorElement;

    if (deskproCloseButton) {
      this._widgetCloseButton = deskproCloseButton;
    } else {
      this._checkWidgetDiv();
    }
  }

  private _checkWidgetDiv(): void {
    // sometimes inner iframe is not available immediately,
    // so instead of mutation observer, used interval check
    this._intervalId = unpatchedSetInterval(() => {
      const widgetContiner = document.querySelector<HTMLDivElement>(
        '#dp_widget_container'
      );

      if (widgetContiner && this._intervalId) {
        this._disposeTimer();
        this._setAndHideWidgetButton(widgetContiner);
        this._setWidgetCloseButton(widgetContiner);
      }
    }, 400);
  }

  private _disposeTimer(): void {
    if (this._intervalId !== null) {
      unpatchedClearInterval(this._intervalId);
      this._intervalId = null;
    }
  }
}

function loadDeskPro(
  config: DeskProConfig,
  scriptHasBeenLoaded$: ReplaySubject<void>
) {
  loadScript({
    src: `${config.helpDeskUrl}/dyn-assets/pub/build/widget_loader.min.js`,
    onLoad: () => {
      scriptHasBeenLoaded$.next();
      scriptHasBeenLoaded$.complete();
    },
  });
}

export function provideDeskPro(): Provider[] {
  return [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        // Do nothing in Node.js.
        if (global_isServer) {
          return () => {};
        }

        const service = inject(DeskProService);
        return () => service.initialize();
      },
    },
  ];
}
