import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  catchError,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  timeout,
} from 'rxjs';

import { LocalStorageService } from '@cosmos/data-access-storage';
import { injectNavigator } from '@cosmos/util-common';
import { dayjs } from '@cosmos/util-dayjs';

@Injectable({ providedIn: 'root' })
export class SwUpdateService {
  private readonly _router = inject(Router);
  private readonly _swUpdate = inject(SwUpdate);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _httpBackend = inject(HttpBackend);
  private readonly _http = new HttpClient(this._httpBackend);
  private readonly _navigator = injectNavigator();
  private _lastUpdatedDate = new Date();
  private readonly _ngswTimeStampKey = 'ngswTimeStamp';

  async waitForUpdate(): Promise<void> {
    const versionReady = this._swUpdate.versionUpdates.pipe(
      filter((event) => event.type === 'VERSION_READY'),
      take(1)
    );

    await firstValueFrom(versionReady);

    // If there's any new version available let's wait for the first navigation end event
    // to reload the page. We have to wait until the routing completely ends and the browser
    // history is updated with the browser URL.
    const firstNavigationWithoutState = this._router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationEnd &&
          // Wait until it's a navigation w/o the state provided.
          this._router.getCurrentNavigation()?.extras.state === undefined
      ),
      take(1)
    );

    await firstValueFrom(firstNavigationWithoutState);

    this._unregisterAndReload();
  }

  async forceReloadAfterXDays(days?: number) {
    if (ngDevMode || !days) {
      return;
    }

    const firstNavigationWithoutState = this._router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationEnd &&
          // Wait until it's a navigation w/o the state provided.
          this._router.getCurrentNavigation()?.extras.state === undefined
      ),
      switchMap(() => {
        const daysDiff = dayjs().diff(dayjs(this._lastUpdatedDate), 'days');

        if (daysDiff >= days) {
          return this._http.get<{ timestamp: string }>('ngsw.json').pipe(
            // If the request takes more than 1 second, we assume that the network is slow
            // and we should force reload the page via the catchError operator
            timeout(1000),
            map((response) => {
              const ngswTimeStamp = this._localStorageService.getItem(
                this._ngswTimeStampKey
              );

              // If the local timestamp is different from the one in the ngsw.json,
              // it means that we have a new version and we should force reload the page.
              const shouldForceReload = ngswTimeStamp !== response.timestamp;

              if (!shouldForceReload) {
                // To avoid refetching the ngsw.json file every time we navigate,
                // we set the last updated date to the current date.
                this._lastUpdatedDate = new Date();
              }
              this._localStorageService.setItem(
                this._ngswTimeStampKey,
                response.timestamp
              );

              return shouldForceReload;
            }),
            catchError(() => {
              // In case of an error, force reload the page
              return of(true);
            })
          );
        }

        return of(false);
      }),
      filter(Boolean),
      take(1)
    );

    await firstValueFrom(firstNavigationWithoutState);

    this._unregisterAndReload();
  }

  private async _unregisterAndReload() {
    try {
      await this._unregisterAll();
    } finally {
      location.reload();
    }
  }

  private async _unregisterAll(): Promise<void> {
    const registrations =
      await this._navigator.serviceWorker.getRegistrations();

    await Promise.all(
      registrations.map((registration) => registration.unregister())
    );
  }
}
