@if (config$ | async; as config) {
  <div id="main">
    <!-- NAVBAR -->
    @if (config.layout?.['navbar']?.position === 'top' && showHeader()) {
      <ng-content select="cos-global-header" />
    }
    <!-- / NAVBAR -->
    <div
      id="container-1"
      class="esp-encore-layout-container-wrapper esp-encore-layout-container"
    >
      <div
        id="container-2"
        class="esp-encore-layout-container-outer esp-encore-layout-container"
      >
        <div
          id="container-3"
          class="esp-encore-layout-container-inner esp-encore-layout-container"
          [ngClass]="{
            'esp-encore-layout-container-inner--no-header': !showHeader(),
            'esp-encore-layout-container-inner--no-footer': !showFooter(),
          }"
        >
          <!-- CONTENT -->
          <div id="content" class="esp-encore-layout-inner-scroll">
            <router-outlet />
          </div>
          <!-- / CONTENT -->
          <!-- FOOTER: Below static -->
          @if (
            config.layout?.['footer']?.position === 'below-static' &&
            showFooter()
          ) {
            <ng-content select="esp-feature-global-footer,esp-footer" />
          }
          <!-- / FOOTER: Below static -->
        </div>
      </div>
    </div>
  </div>
}
