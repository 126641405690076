/* eslint-disable no-restricted-globals */
import { makeEnvironmentProviders } from '@angular/core';
import {
  defaultModules,
  QUILL_CONFIG_TOKEN,
  type QuillConfig,
} from 'ngx-quill/config';
import { defer, EMPTY, shareReplay } from 'rxjs';

const quillCSS$ = defer(() => {
  if (global_isServer) {
    return EMPTY;
  }

  // eslint-disable-next-line @nx/enforce-module-boundaries
  return import(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /* webpackIgnore: true */ '/assets/js/quill/quill-styles.min.js'
  ).then(({ quillCore, quillBubble, quillSnow }) => {
    if (global_isServer) {
      return;
    }

    const stylesList = [
      'quill-core',
      quillCore,
      'quill-bubble',
      quillBubble,
      'quill-snow',
      quillSnow,
    ];

    for (let index = 0; index < stylesList.length; index++) {
      const id = stylesList[index];
      const styles = stylesList[++index];

      if (document.getElementById(id)) continue;

      const style = document.createElement('style');
      style.id = id;
      style.appendChild(document.createTextNode(styles));
      document.head.appendChild(style);
    }
  });
}).pipe(shareReplay({ bufferSize: 1, refCount: false }));

const config: QuillConfig = {
  modules: defaultModules,
  beforeRender: () => quillCSS$,
};

export function provideQuillConfig() {
  return makeEnvironmentProviders([
    { provide: QUILL_CONFIG_TOKEN, useValue: config },
  ]);
}
