import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { PreferredSupplierActions } from './actions';
import {
  PreferredSupplierListCompaniesState,
  PreferredSupplierListsState,
  PreferredSupplierListWebsitesState,
  PreferredSupplierMergedListState,
  PreferredSupplierState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PreferredSupplierState,
      PreferredSupplierListsState,
      PreferredSupplierMergedListState,
      PreferredSupplierListWebsitesState,
      PreferredSupplierListCompaniesState,
    ]),
  ],
  providers: [
    AlgoliaTasksInterceptor.create({
      urlPattern: /(semaphore\/suppliers)|(semaphore\/lists)/,
      ActionToDispatch: PreferredSupplierActions.SearchIndexOperationComplete,
    }),
  ],
})
export class EspPreferredSuppliersDataAccessModule {}
