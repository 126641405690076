import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

import { CosButtonComponent } from '@cosmos/components/button';
import { LocalStorageService } from '@cosmos/data-access-storage';
import { ExplicitFeatureFlagsService } from '@cosmos/feature-flags';
import {
  CosFeatureFlagsToolbarComponent,
  CosFeatureFlagsToolbarControlsSet,
} from '@cosmos/feature-flags-toolbar';
import { DialogService } from '@cosmos/ui-dialog';
import { injectWindow } from '@cosmos/util-common';

import { featureFlagsEditorDialogDef } from './feature-flags-editor';

@Component({
  selector: 'cos-feature-flags-select',
  templateUrl: './feature-flags-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, CosButtonComponent],
})
export class CosFeatureFlagsSelectComponent {
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _window = injectWindow();
  private readonly _dialogService = inject(DialogService);

  constructor(
    readonly featureFlagsToolbarComponent: CosFeatureFlagsToolbarComponent
  ) {}

  changeFeatureFlagMode(): void {
    this._localStorageService.setItem(
      ExplicitFeatureFlagsService.featureFlagGroupStorageKey,
      this.featureFlagsToolbarComponent.featureFlag
    );
    this._window.location.reload();
  }

  async openFeatureFlagsEditor() {
    await firstValueFrom(this._dialogService.open(featureFlagsEditorDialogDef));
  }
}

export function withFlagsSelect() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsSelectComponent);
      },
    },
  ]);
}
